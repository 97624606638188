<template>
  <VLayout :class="['menu-layout', { isDirty }]" row wrap>
    <VFlex xs12 md4 grow>
      <BaseEditable
        v-if="menu.title"
        tag="h3.blanch--font"
        :content="menu.title"
        :mods="myMods.title"
        :class="[
          'menu-layout--title text-xs-center text-md-left',
          { 'has-subtitle': subtitle },
        ]"
        @dirtyUpdate="adjustDirtScore"
      />
      <div
        v-if="subtitle"
        class="menu-layout--subtitle text-xs-center text-md-left"
      >
        <BaseEditable
          tag="span"
          :content="subtitle"
          :mods="myMods.meta_info"
          @update="updateSectionAction('meta_info', $event)"
          @dirtyUpdate="adjustDirtScore"
        />
      </div>
    </VFlex>
    <VFlex
      v-for="(sectionId, i) of sectionIds"
      :key="sectionId"
      xs12
      md4
      grow
      class="menu-section--wrap"
    >
      <WithMenuSection
        :id="sectionId"
        v-slot="{
          isSectionLoading,
          section,
          itemMods,
          myMods: sectionMods,
        }"
        :key="sectionId"
      >
        <BaseMenuComponent
          v-if="section.is_callout"
          :ref="`section-${i}`"
          :key="sectionId"
          name="MenuSectionCallout"
          :entity="section"
          :item-mods="itemMods"
          :my-mods="sectionMods"
          @dirtyUpdate="adjustDirtScore"
        />
        <BaseMenuComponent
          v-else
          :ref="`section-${i}`"
          :key="sectionId"
          name="MenuSection"
          :entity="section"
          :item-mods="itemMods"
          :my-mods="sectionMods"
          @dirtyUpdate="adjustDirtScore"
        />
      </WithMenuSection>
    </VFlex>
  </VLayout>
</template>

<script>
import MenuLayoutMixin from '@mixins/menu-layout'

export default {
  name: 'MenuLayoutKids',
  mixins: [MenuLayoutMixin],
  props: {
    myMods: {
      type: [Object, Boolean],
      default: () => {},
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.menu-layout-kids {
  &.isDirty {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: purple;
      transform: translateY(-200%);
    }
  }
}

.menu-layout--title {
  &.has-subtitle {
    margin-bottom: 0.1em;
  }
}
.menu-layout--subtitle {
  font-size: 1.8rem;
  font-weight: 500;
  span {
    font-family: 'motor', serif;
  }
  i {
    font-family: 'Asap', sans-serif;
    // font-weight:300;
    font-size: 0.8rem;
  }
}
</style>

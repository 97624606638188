import _get from 'lodash/get'
import { ENTITY_LOOKUP } from '@constants/lookupTables'
import WithMenuSection from '@dataProviders/WithMenuSection'
import GetsDirty from '@mixins/gets-dirty'
import { mapGetters, mapActions } from 'vuex'

export var MenuLayoutMixin = {
  components: {
    WithMenuSection,
  },
  mixins: [GetsDirty(ENTITY_LOOKUP.Menu)],
  props: {
    menu: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      id: this.menu.id,
    }
  },
  computed: {
    ...mapGetters('sections', ['getSections']),

    sectionIds() {
      return this.menu.sections.filter((s) => !s.parent_id)
    }, // sectionIds

    sections() {
      return this.getSections(this.sectionIds)
    }, // sections

    hasDirtySections() {
      return !!this.dirtySections.length
    }, // hasDirtySections

    dirtySections() {
      return this.sections.filter((s) => s.isDirty)
    }, // dirtySections

    meta_info() {
      return JSON.parse(_get(this.menu, 'meta_info', '{}'))
    }, // meta_info

    type() {
      return _get(this.meta_info, 'type', 'default')
    }, // type

    hasLegend() {
      return _get(this.meta_info, 'has_legend', false)
    }, // hasLegend

    subtitle() {
      return _get(this.meta_info, 'subtitle', false)
    }, // hasLegend
  }, // computed

  methods: {
    ...mapActions('menus', [
      'updateSectionItems',
      'trySectionMod',
      'trySectionItemMod',
    ]),
  },
}

export default MenuLayoutMixin

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VLayout',{class:['menu-layout', { isDirty: _vm.isDirty }],attrs:{"row":"","wrap":""}},[_c('VFlex',{attrs:{"xs12":"","md4":"","grow":""}},[(_vm.menu.title)?_c('BaseEditable',{class:[
        'menu-layout--title text-xs-center text-md-left',
        { 'has-subtitle': _vm.subtitle } ],attrs:{"tag":"h3.blanch--font","content":_vm.menu.title,"mods":_vm.myMods.title},on:{"dirtyUpdate":_vm.adjustDirtScore}}):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"menu-layout--subtitle text-xs-center text-md-left"},[_c('BaseEditable',{attrs:{"tag":"span","content":_vm.subtitle,"mods":_vm.myMods.meta_info},on:{"update":function($event){return _vm.updateSectionAction('meta_info', $event)},"dirtyUpdate":_vm.adjustDirtScore}})],1):_vm._e()],1),_vm._l((_vm.sectionIds),function(sectionId,i){return _c('VFlex',{key:sectionId,staticClass:"menu-section--wrap",attrs:{"xs12":"","md4":"","grow":""}},[_c('WithMenuSection',{key:sectionId,attrs:{"id":sectionId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var isSectionLoading = ref.isSectionLoading;
      var section = ref.section;
      var itemMods = ref.itemMods;
      var sectionMods = ref.myMods;
return [(section.is_callout)?_c('BaseMenuComponent',{key:sectionId,ref:("section-" + i),refInFor:true,attrs:{"name":"MenuSectionCallout","entity":section,"item-mods":itemMods,"my-mods":sectionMods},on:{"dirtyUpdate":_vm.adjustDirtScore}}):_c('BaseMenuComponent',{key:sectionId,ref:("section-" + i),refInFor:true,attrs:{"name":"MenuSection","entity":section,"item-mods":itemMods,"my-mods":sectionMods},on:{"dirtyUpdate":_vm.adjustDirtScore}})]}}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }